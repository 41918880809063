/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Navigation custom styles */
@media (max-width: 767px) {
  .navicon {
      height: .125em;
      width: 1.125em;
  }

  .navicon::before,
  .navicon::after {
      background: #3D4852;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      transition: all .2s ease-out;
      width: 100%;
  }

  .navicon::before {
      top: 5px;
  }

  .navicon::after {
      top: -5px;
  }

  .menu-btn:not(:checked)~.menu {
      display: none;
  }

  .menu-btn:checked~.menu {
      display: block;
  }

  .menu-btn:checked~.menu-icon .navicon {
      background: transparent;
  }

  .menu-btn:checked~.menu-icon .navicon::before {
      transform: rotate(-45deg);
  }

  .menu-btn:checked~.menu-icon .navicon::after {
      transform: rotate(45deg);
  }

  .menu-btn:checked~.menu-icon .navicon::before,
  .menu-btn:checked~.menu-icon .navicon::after {
      top: 0;
  }
}

mark {
    border-radius: 1.4em 0 2em;
    background: transparent;
    background-image: linear-gradient(-100deg, hsla(48, 94%, 68%, .3), hsla(48, 94%, 68%, .7) 95%, hsla(48, 94%, 68%, .1));
}

@layer base {
    main .prose h1, main .prose h2, main .prose h3, main .prose h4, main .prose h5, main .prose h6 {
        @apply text-gray-900;
    }
    main .prose h2 {
        @apply pt-8;
    }
    h2#table-of-contents {
        @apply mt-8;
    }
    ul#markdown-toc {
        @apply list-none mb-12 pr-8 max-w-fit border;
    }
    ul#markdown-toc li a, .post-body a {
        @apply no-underline text-orange-600 hover:text-orange-700 border-b hover:border-b-2 border-orange-600 hover:border-orange-700;
    }
    div#list-cities-footer a {
        @apply underline text-blue-200 hover:text-white;
    }
}
